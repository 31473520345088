import { NextPage } from 'next';
import React from 'react';

import { CommonLayout } from '~/atomic/layout/CommonLayout';
import { LayoutType } from '~/atomic/layout/DefaultLayout';
import { NotFoundPage } from '~/atomic/page/notFoundPage/NotFoundPage';

export const Page404: NextPage & LayoutType = () => {
  return (
    <NotFoundPage />
  );
};

Page404.getLayout = (page) => (
  <CommonLayout>{ page }</CommonLayout>
);

export default Page404;
