import React from 'react';
import styled from 'styled-components';

import { Divider } from '~/atomic/atom/Divider';
import { PopularThemesSlider } from '~/atomic/page/index/FeedPopularThemesBanner/PopularThemesSlider';
import {
  PageTitleStyle, TextBoldStyle, TextRegStyle,
} from '~/atomic/Typography';
import { CheckAuthOnErrorPage } from '~/feature/authorization/CheckAuth';
import { ProviderAuth } from '~/feature/authorization/ProviderAuth';
import { TopProgressBar } from '~/feature/topProgressBar/TopProgressBar';
import { lessThan } from '~/lib/mediaQuery';

export const NotFoundPage = () => {
  return (
    <SCNotFoundPage>
      <SCBrokenBookImg
        alt="Книга с порванной страницей"
        src="/image/broken-book.svg"
      />
      <SCErrorName>
        <SCErrorTitle>
          404
        </SCErrorTitle>
        Страница не найдена
      </SCErrorName>
      <SCMessage>
        Вероятно, запрашиваемая страница не существует или была перемещена по другому адресу.
        Что ж, и такое бывает
      </SCMessage>
      <SCBooksWrapper>
        <Divider />
        <SCTitle>
          Что еще можно почитать?
        </SCTitle>
        <SCWrapper>
          <PopularThemesSlider />
        </SCWrapper>
      </SCBooksWrapper>
      <>
        <CheckAuthOnErrorPage />
        <TopProgressBar />
        <ProviderAuth />
      </>
    </SCNotFoundPage>
  );
};

const SCTitle = styled.h2`
  ${PageTitleStyle};
  text-align: center;
  margin-bottom: 20px;
`;

const SCErrorName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
  ${TextBoldStyle};
  color: var(--black-color);
`;

const SCErrorTitle = styled.span`
  font-size: 28px;
  line-height: 20px;
  margin-bottom: 4px;
`;

const SCMessage = styled.span`
  ${TextRegStyle};
  color: var(--gray-color);
  max-width: 514px;
  text-align: center;
  margin-bottom: 16px;
`;

const SCBrokenBookImg = styled.img`
  width: 183.36px;
  height: 124px;
  margin-bottom: 42px;
`;

const SCNotFoundPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`;

const SCBooksWrapper = styled.div`
  max-width: 760px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  padding-right: 24px;
  padding-left: 24px;
  
  ${lessThan('sm')} {
    padding-right: 12px;
    padding-left: 12px;
    margin-bottom: 20px;
  }
`;

const SCWrapper = styled.div`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;
