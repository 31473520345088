import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

import { providerCallback } from '~/feature/authorization/auth.data';
import { useAppDispatch } from '~/store';

export const ProviderAuth: FC = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    dispatch(providerCallback());
  }, [router]);

  return null;
};
